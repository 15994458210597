// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("/opt/build/repo/src/Templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-auto-login-page-js": () => import("/opt/build/repo/src/Templates/AutoLoginPage.js" /* webpackChunkName: "component---src-templates-auto-login-page-js" */),
  "component---src-templates-redirect-page-js": () => import("/opt/build/repo/src/Templates/RedirectPage.js" /* webpackChunkName: "component---src-templates-redirect-page-js" */),
  "component---src-templates-videos-page-js": () => import("/opt/build/repo/src/Templates/VideosPage.js" /* webpackChunkName: "component---src-templates-videos-page-js" */),
  "component---src-templates-profile-page-js": () => import("/opt/build/repo/src/Templates/ProfilePage.js" /* webpackChunkName: "component---src-templates-profile-page-js" */),
  "component---src-templates-create-record-page-js": () => import("/opt/build/repo/src/Templates/CreateRecordPage.js" /* webpackChunkName: "component---src-templates-create-record-page-js" */),
  "component---src-templates-edit-record-page-js": () => import("/opt/build/repo/src/Templates/EditRecordPage.js" /* webpackChunkName: "component---src-templates-edit-record-page-js" */),
  "component---src-templates-record-detail-page-js": () => import("/opt/build/repo/src/Templates/RecordDetailPage.js" /* webpackChunkName: "component---src-templates-record-detail-page-js" */),
  "component---src-templates-archive-page-js": () => import("/opt/build/repo/src/Templates/ArchivePage.js" /* webpackChunkName: "component---src-templates-archive-page-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

